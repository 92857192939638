export var SAVE_FUEL_SAVINGS = 'SAVE_FUEL_SAVINGS';
export var CALCULATE_FUEL_SAVINGS = 'CALCULATE_FUEL_SAVINGS'; // --------------------------------
// export const LOGIN = 'LOGIN';
// export const LOADING = 'LOADING';
// export const LOGIN_FAIL = 'LOGIN_FAIL';
// ----------common type --------------//

export var ACTION_REQUEST = 'ACTION_REQUEST';
export var ACTION_FAILURE = 'ACTION_FAILURE';
export var REQUEST_FAILURE = 'REQUEST_FAILURE';
export var RESET_STATE = 'RESET_STATE';
export var RESET_ERROR = 'RESET_ERROR';
export var LOGIN_REQUEST = 'LOGIN_REQUEST';
export var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export var LOGOUT = 'LOGOUT';
export var UNAUTHORIZED_FAILURE = 'UNAUTHORIZED_FAILURE';
export var INACTIVE_COMPANY = 'INACTIVE_COMPANY';
export var LOGIN_FAILURE = 'LOGIN_FAILURE';
export var USER_NOT_ADMIN = 'USER_NOT_ADMIN';
export var USER_NOT_ADMIN_REQUEST_FAILURE = 'USER_NOT_ADMIN_REQUEST_FAILURE';
export var CHECK_EMAIL = 'CHECK_EMAIL'; // -------------users------------------//

export var GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export var GET_ASSIGNABLE_USERS_SUCCESS = 'GET_ASSIGNABLE_USERS_SUCCESS';
export var GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export var REQUEST_USER_FAILURE = 'REQUEST_USER_FAILURE';
export var REQUEST_ASSIGNABLE_USERS_FAILURE = 'REQUEST_ASSIGNABLE_USERS_FAILURE';
export var GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export var UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export var ADD_SUBCONTRACTOR_SUCCESS = 'ADD_SUBCONTRACTOR_SUCCESS';
export var ADD_SUBCONTRACTOR_FAILURE = 'ADD_SUBCONTRACTOR_FAILURE';
export var ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export var ADD_USER_FAILURE = 'ADD_USER_FAILURE'; // -------------clients----------------//

export var GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export var REQUEST_CLIENTS_FAILURE = 'REQUEST_CLIENTS_FAILURE';
export var GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS';
export var UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export var REST_EDIT_SUCCESS = 'REST_EDIT_SUCCESS';
export var REST_ADD_SUCCESS = 'REST_ADD_SUCCESS';
export var ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export var ENABLE_CLIENT_SUCCESS = 'ENABLE_CLIENT_SUCCESS';
export var ENABLE_CLIENT_FAILURE = 'ENABLE_CLIENT_FAILURE';
export var DISABLE_CLIENT_SUCCESS = 'DISABLE_CLIENT_SUCCESS';
export var DISABLE_CLIENT_FAILURE = 'DISABLE_CLIENT_FAILURE';
export var ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';
export var EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';
export var EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS'; // -----------signup---------------//

export var SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export var SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'; // -------------reports-------------//

export var GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export var GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export var GET_PDF_SUCCESS = 'GET_PDF_SUCCESS';
export var GET_PDF_FAILURE = 'GET_PDF_FAILURE';
export var SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export var SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export var EXPORT_REPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export var EXPORT_REPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE'; // -------------reporting------------- //

export var GET_SALES_DATA_SUCCESS = 'GET_SALES_DATA_SUCCESS';
export var GET_SALES_DATA_FAILURE = 'GET_SALES_DATA_FAILURE';
export var GET_SALES_CHART_DATA_SUCCESS = 'GET_SALES_CHART_DATA_SUCCESS';
export var GET_SALES_CHART_DATA_FAILURE = 'GET_SALES_CHART_DATA_FAILURE'; // ------------invoices------------//

export var GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export var GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
export var GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export var GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
export var ADD_TO_QB_SUCCESS = 'ADD_TO_QB_SUCCESS';
export var ADD_TO_QB_FAILURE = 'ADD_TO_QB_FAILURE';
export var EXPORT_INVOICES_SUCCESS = 'EXPORT_INVOICES_SUCCESS';
export var EXPORT_INVOICES_FAILURE = 'EXPORT_INVOICES_FAILURE';
export var EXPORT_INVOICES_CSV_SUCCESS = 'EXPORT_INVOICES_CSV_SUCCESS';
export var EXPORT_INVOICES_CSV_FAILURE = 'EXPORT_INVOICES_CSV_FAILURE';
export var EXPORT_VEHICLES_CSV_SUCCESS = 'EXPORT_VEHICLES_CSV_SUCCESS';
export var EXPORT_VEHICLES_CSV_FAILURE = 'EXPORT_VEHICLES_CSV_FAILURE';
export var EXPORT_SERVICES_SUCCESS = 'EXPORT_SERVICES_SUCCESS';
export var EXPORT_SERVICES_FAILURE = 'EXPORT_SERVICES_FAILURE';
export var UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';
export var UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export var CALL_PRINTER_SUCCESS = 'CALL_PRINTER_SUCCESS';
export var CALL_PRINTER_FAILURE = 'CALL_PRINTER_FAILURE';
export var SEND_INVOICE_EMAIL = 'SEND_INVOICE_EMAIL';
export var SEND_INVOICE_EMAIL_SUCCESS = 'SEND_INVOICE_EMAIL_SUCCESS';
export var SEND_INVOICE_EMAIL_FAILURE = 'SEND_INVOICE_EMAIL_FAILURE';
export var GET_INCOMING_INVOICE_SUCCESS = 'GET_INCOMING_INVOICE_SUCCESS';
export var GET_INCOMING_INVOICE_FAILURE = 'GET_INCOMING_INVOICE_FAILURE';
export var GET_EMAIL_INVOICE_SUCCESS = 'GET_EMAIL_INVOICE_SUCCESS';
export var GET_EMAIL_INVOICE_FAILURE = 'GET_EMAIL_INVOICE_FAILURE'; // ------------estimates------------//

export var GET_LEADS_ESTIMATE_SUCCESS = 'GET_LEADS_ESTIMATE_SUCCESS';
export var GET_LEADS_ESTIMATE_FAILURE = 'GET_LEADS_ESTIMATE_FAILURE';
export var GET_ESTIMATES_SUCCESS = 'GET_ESTIMATES_SUCCESS';
export var GET_ESTIMATES_FAILURE = 'GET_ESTIMATES_FAILURE';
export var GET_ESTIMATE_SUCCESS = 'GET_ESTIMATES_SUCCESS';
export var GET_ESTIMATE_FAILURE = 'GET_ESTIMATES_FAILURE';
export var EXPORT_ESTIMATES_CSV_SUCCESS = 'EXPORT_ESTIMATES_CSV_SUCCESS';
export var EXPORT_ESTIMATES_CSV_FAILURE = 'EXPORT_ESTIMATES_CSV_FAILURE';
export var EXPORT_ESTIMATES_VEHICLES_CSV_SUCCESS = 'EXPORT_ESTIMATES_VEHICLES_CSV_SUCCESS';
export var EXPORT_ESTIMATES_VEHICLES_CSV_FAILURE = 'EXPORT_ESTIMATES_VEHICLES_CSV_FAILURE';
export var EXPORT_ESTIMATES_CSV = 'EXPORT_ESTIMATES_CSV';
export var EXPORT_ERAC_SUCCESS = 'EXPORT_ERAC_SUCCESS';
export var EXPORT_ERAC_FAILURE = 'EXPORT_ERAC_FAILURE';
export var UPDATE_ESTIMATE_FAILURE = 'UPDATE_ESTIMATE_FAILURE';
export var UPDATE_ESTIMATE_SUCCESS = 'UPDATE_ESTIMATE_SUCCESS';
export var SEND_ESTIMATE_EMAIL = 'SEND_ESTIMATE_EMAIL';
export var SEND_ESTIMATE_EMAIL_SUCCESS = 'SEND_ESTIMATE_EMAIL_SUCCESS';
export var SEND_ESTIMATE_EMAIL_FAILURE = 'SEND_ESTIMATE_EMAIL_FAILURE';
export var GET_INCOMING_ESTIMATE_SUCCESS = 'GET_INCOMING_ESTIMATE_SUCCESS';
export var GET_INCOMING_ESTIMATE_FAILURE = 'GET_INCOMING_ESTIMATE_FAILURE';
export var ESTIMATE_DOWNLOAD_SUCCESS = 'ESTIMATE_DOWNLOAD_SUCCESS';
export var ESTIMATE_DOWNLOAD_FAILURE = 'ESTIMATE_DOWNLOAD_FAILURE';
export var LOAD_ESTIMATE_DETAIL_SUCCESS = 'LOAD_ESTIMATE_DETAIL_SUCCESS';
export var GET_ESTIMATE_DETAIL_FAILURE = 'GET_ESTIMATE_DETAIL_FAILURE';
export var LOAD_ESTIMATE_DETAIL_REQUEST = 'LOAD_ESTIMATE_DETAIL_REQUEST';
export var UPDATE_VEHICLE_ESTIMATE_SUCCESS = 'UPDATE_VEHICLE_ESTIMATE_SUCCESS';
export var RESET_ESTIMATE_DETAIL = 'RESET_ESTIMATE_DETAIL';
export var UPDATE_ESTIMATE_DETAIL_SUCCESS = 'UPDATE_ESTIMATE_DETAIL_SUCCESS';
export var ESTIMATE_DETAIL_DOWNLOAD_SUCCESS = 'ESTIMATE_DETAILT_DOWNLOAD_SUCCESS';
export var ESTIMATE_DOWNLOAD_DETAIL_FAILURE = 'ESTIMATE_DOWNLOAD_DETAIL_FAILURE';
export var GET_EMAIL_ESTIMATE_SUCCESS = 'GET_EMAIL_ESTIMATE_SUCCESS';
export var GET_EMAIL_ESTIMATE_FAILURE = 'GET_EMAIL_ESTIMATE_FAILURE'; // --=--------EXPORTS-------------//

export var GET_EXPORTS_SUCCESS = 'GET_EXPORTS_SUCCESS';
export var GET_EXPORTS_FAILURE = 'GET_EXPORTS_FAILURE'; // --=--------COMPANY-------------//

export var LOAD_COMPANY = 'LOAD_COMPANY';
export var LOAD_COMPANIES = 'LOAD_COMPANIES';
export var SWITCH_COMPANY_SUCCESS = 'SWITCH_COMPANY_SUCCESS';
export var SWITCH_COMPANY_FAILURE = 'SWITCH_COMPANY_FAILURE';
export var LOAD_SUBSCRIPTION_SUCCESS = 'LOAD_SUBSCRIPTION_SUCCESS';
export var LOAD_SUBSCRIPTION_FAILURE = 'LOAD_SUBSCRIPTION_FAILURE';
export var PURCHASE_CREDITS_SUCCESS = 'PURCHASE_CREDITS_SUCCESS';
export var PURCHASE_CREDITS_FAILURE = 'PURCHASE_CREDITS_FAILURE';
export var EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';
export var EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export var CHANGE_COMPANY_LOGO_FAILURE = 'CHANGE_COMPANY_LOGO_FAILURE';
export var CHANGE_COMPANY_LOGO_SUCCESS = 'CHANGE_COMPANY_LOGO_SUCCESS';
export var UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export var UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export var CHANGE_SUBSCRIPTIONPLAN_SUCCESS = 'CHANGE_SUBSCRIPTIONPLAN_SUCCESS';
export var CHANGE_SUBSCRIPTIONPLAN_FAILURE = 'CHANGE_SUBSCRIPTIONPLAN_FAILURE';
export var UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';
export var UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export var ADD_NEW_PAYMENT_FAILURE = 'ADD_NEW_PAYMENT_FAILURE';
export var ADD_NEW_PAYMENT_SUCCESS = 'ADD_NEW_PAYMENT_SUCCESS';
export var GET_PRICE_A_DENT = 'GET_PRICE_A_DENT';
export var UPDATE_PRICE_A_DENT_FAILURE = 'UPDATE_PRICE_A_DENT_FAILURE';
export var UPDATE_PRICE_A_DENT_SUCCESS = 'UPDATE_PRICE_A_DENT_SUCCESS';
export var GET_SIGNED_REQUEST_FAILURE = 'GET_SIGNED_REQUEST_FAILURE';
export var GET_SIGNED_REQUEST_SUCCESS = 'GET_SIGNED_REQUEST_SUCCESS';
export var UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export var UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export var IMPORT_CSV_SUCCESS = 'IMPORT_CSV_SUCCESS';
export var IMPORT_CSV_FAILURE = 'IMPORT_CSV_FAILURE';
export var RESET_IMPORT_CSV = 'RESET_IMPORT_CSV';
export var EXPORT_CSV_REQUEST = 'EXPORT_CSV_REQUEST';
export var EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE';
export var EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export var WISETACK_SIGNUP_REQUEST = 'WISETACK_SIGNUP_REQUEST';
export var WISETACK_SIGNUP_SUCCESS = 'WISETACK_SIGNUP_SUCCESS';
export var WISETACK_SIGNUP_FAILURE = 'WISETACK_SIGNUP_FAILURE';
export var UPDATE_SERVICES_USER_SUCCESS = 'UPDATE_SERVICES_USER_SUCCESS';
export var UPDATE_SERVICES_USER_FAILURE = 'UPDATE_SERVICES_USER_FAILURE'; // export const GET_QUICKBOOKS_SETTINGS_SUCCESS = 'GET_QUICKBOOKS_SETTINGS_SUCCESS';
// export const GET_QUICKBOOKS_SETTINGS_FAILURE = 'GET_QUICKBOOKS_SETTINGS_FAILURE';
// -------------tableau----------------//

export var TABLEAU_TICKET_REQUEST = 'TABLEAU_TICKET_REQUEST';
export var TABLEAU_TICKET_SUCCESS = 'TABLEAU_TICKET_SUCCESS';
export var TABLEAU_TICKET_FAILURE = 'TABLEAU_TICKET_FAILURE'; // -------------matrices----------------//

export var GET_MATRICES_SUCCESS = 'GET_MATRICES_SUCCESS';
export var GET_MATRIX_SUCCESS = 'GET_MATRIX_SUCCESS';
export var GET_MATRICES_FAILURE = 'GET_MATRICES_FAILURE';
export var UPLOAD_HAIL_MATRIX_SUCCESS = 'UPLOAD_HAIL_MATRIX_SUCCESS';
export var UPLOAD_HAIL_MATRIX_FAILURE = 'UPLOAD_HAIL_MATRIX_FAILURE';
export var DELETE_MATRIX_SUCCESS = 'DELETE_MATRIX_SUCCESS';
export var DELETE_MATRIX_FAILURE = 'DELETE_MATRIX_FAILURE';
export var ADD_PRICE_A_DENT_MATRIX_SUCCESS = 'ADD_PRICE_A_DENT_MATRIX_SUCCESS';
export var ADD_PRICE_A_DENT_MATRIX_FAILURE = 'ADD_PRICE_A_DENT_MATRIX_FAILURE';
export var EDIT_PRICE_A_DENT_MATRIX_SUCCESS = 'EDIT_PRICE_A_DENT_MATRIX_SUCCESS';
export var EDIT_PRICE_A_DENT_MATRIX_FAILURE = 'EDIT_PRICE_A_DENT_MATRIX_FAILURE'; // ----------------workOrder--------------//

export var WORK_ORDER_GET_ALL_REQUEST = 'WORK_ORDER_GET_ALL_REQUEST';
export var WORK_ORDER_GET_ALL_SUCCESS = 'WORK_ORDER_GET_ALL_SUCCESS';
export var WORK_ORDER_GET_ALL_FAILURE = 'WORK_ORDER_GET_ALL_FAILURE';
export var WORK_ORDER_GET_PDF_REQUEST = 'WORK_ORDER_GET_PDF_REQUEST';
export var WORK_ORDER_GET_PDF_SUCCESS = 'WORK_ORDER_GET_PDF_SUCCESS';
export var WORK_ORDER_GET_PDF_FAILURE = 'WORK_ORDER_GET_PDF_FAILURE';
export var WORK_ORDER_SEND_EMAIL = 'WORK_ORDER_SEND_EMAIL';
export var WORK_ORDER_UPDATE_REQUEST = 'WORK_ORDER_UPDATE_REQUEST';
export var WORK_ORDER_UPDATE_SUCCESS = 'WORK_ORDER_UPDATE_SUCCESS';
export var WORK_ORDER_UPDATE_FAILURE = 'WORK_ORDER_UPDATE_FAILURE'; // ----------------cogs--------------//

export var COGS_GET_REQUEST = 'COGS_GET_REQUEST';
export var COGS_GET_SUCCESS = 'COGS_GET_SUCCESS';
export var COGS_GET_FAILURE = 'COGS_GET_FAILURE';
export var COGS_UPDATE_REQUEST = 'COGS_UPDATE_REQUEST';
export var COGS_UPDATE_SUCCESS = 'COGS_UPDATE_SUCCESS';
export var COGS_UPDATE_FAILURE = 'COGS_UPDATE_FAILURE';
export var COGS_ADD_REQUEST = 'COGS_ADD_REQUEST';
export var COGS_ADD_SUCCESS = 'COGS_ADD_SUCCESS';
export var COGS_ADD_FAILURE = 'COGS_ADD_FAILURE'; // ----------------workflow--------------//

export var WORKFLOW_GET_REQUEST = 'WORKFLOW_GET_REQUEST';
export var WORKFLOW_GET_SUCCESS = 'WORKFLOW_GET_SUCCESS';
export var WORKFLOW_GET_FAILURE = 'WORKFLOW_GET_FAILURE';
export var WORKFLOW_UPDATE_REQUEST = ' WORKFLOW_UPDATE_REQUEST';
export var WORKFLOW_UPDATE_SUCCESS = ' WORKFLOW_UPDATE_SUCCESS';
export var WORKFLOW_UPDATE_FAILURE = ' WORKFLOW_UPDATE_FAILURE';
export var WORKFLOW_CREATE_REQUEST = 'WORKFLOW_CREATE_REQUEST';
export var WORKFLOW_CREATE_SUCCESS = 'WORKFLOW_CREATE_SUCCESS';
export var WORKFLOW_CREATE_FAILURE = 'WORKFLOW_CREATE_FAILURE';
export var WORKFLOW_DELETE_REQUEST = 'WORKFLOW_DELETE_REQUEST';
export var WORKFLOW_DELETE_SUCCESS = 'WORKFLOW_DELETE_SUCCESS';
export var WORKFLOW_DELETE_FAILURE = 'WORKFLOW_DELETE_FAILURE';
export var WORKFLOW_SHOW_MODAL = 'WORKFLOW_SHOW_MODAL';
export var WORKFLOW_SHOW_MODAL_MESSAGE_TYPE = 'WORKFLOW_SHOW_MODAL_MESSAGE_TYPE';
export var WORKFLOW_HIDE_MODAL = 'WORKFLOW_HIDE_MODAL';
export var WORKFLOW_SYNC_DB_SUCCESS = 'WORKFLOW_SYNC_DB_SUCCESS'; // ----------------stage--------------//

export var STAGE_UPDATE_REQUEST = 'STAGE_UPDATE_REQUEST';
export var STAGE_UPDATE_SUCCESS = 'STAGE_UPDATE_SUCCESS';
export var STAGE_UPDATE_FAILURE = 'STAGE_UPDATE_FAILURE';
export var STAGE_CREATE_REQUEST = 'STAGE_CREATE_REQUEST';
export var STAGE_CREATE_SUCCESS = 'STAGE_CREATE_SUCCESS';
export var STAGE_CREATE_FAILURE = 'STAGE_CREATE_FAILURE';
export var STAGE_REQUEST = 'STAGE_REQUEST';
export var STAGE_GET_ESTIMATE_SUCCESS = 'STAGE_GET_ESTIMATE_SUCCESS';
export var STAGE_GET_INVOICE_SUCCESS = 'STAGE_GET_INVOICE_SUCCESS';
export var STAGE_GET_WORK_ORDER_SUCCESS = 'STAGE_GET_WORK_ORDER_SUCCESS';
export var STAGE_SET_VEHICLES = 'STAGE_SET_VEHICLES';
export var STAGE_SET_VEHICLE_SUCCESS = 'STAGE_SET_VEHICLE_SUCCESS';
export var STAGE_UPDATE_ESTIMATE_SUCCESS = 'STAGE_UPDATE_ESTIMATE_SUCCESS';
export var STAGE_UPDATE_WORK_ODER_SUCCESS = 'STAGE_UPDATE_WORK_ODER_SUCCESS';
export var STAGE_UPDATE_INVOICE_SUCCESS = 'STAGE_UPDATE_INVOICE_SUCCESS';
export var STAGE_UPDATE_VEHICLE_SUCCESS = 'STAGE_UPDATE_VEHICLE_SUCCESS';
export var RESET_STAGE = 'RESET_STAGE'; // -------------groups----------------//

export var GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export var GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export var ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export var ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
export var GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export var GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export var DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export var DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export var EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export var EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';
export var REMOVE_CLIENT_IN_GROUP_SUCCESS = 'REMOVE_CLIENT_IN_GROUP_SUCCESS';
export var REMOVE_CLIENT_IN_GROUP_FAILURE = 'REMOVE_CLIENT_IN_GROUP_FAILURE';
export var EDIT_GROUP_ADD_CLIENTS_SUCCESS = 'EDIT_GROUP_ADD_CLIENTS_SUCCESS';
export var EDIT_GROUP_ADD_CLIENTS_FAILURE = 'EDIT_GROUP_ADD_CLIENTS_FAILURE';
export var EDIT_GROUP_EDIT_CLIENT_SUCCESS = 'EDIT_GROUP_EDIT_CLIENT_SUCCESS';
export var EDIT_GROUP_EDIT_CLIENT_FAILURE = 'EDIT_GROUP_EDIT_CLIENT_FAILURE';
export var EDIT_GROUP_EDIT_CLIENT_ADD_USERS_SUCCESS = 'EDIT_GROUP_EDIT_CLIENT_ADD_USERS_SUCCESS';
export var EDIT_GROUP_EDIT_CLIENT_ADD_USERS_FAILURE = 'EDIT_GROUP_EDIT_CLIENT_ADD_USERS_FAILURE';
export var EDIT_GROUP_EDIT_CLIENT_EDIT_USER_SUCCESS = 'EDIT_GROUP_EDIT_CLIENT_EDIT_USER_SUCCESS';
export var EDIT_GROUP_EDIT_CLIENT_EDIT_USER_FAILURE = 'EDIT_GROUP_EDIT_CLIENT_EDIT_USER_FAILURE'; // ----------------quickbooks----------------//

export var GET_QB_COST_OF_GOODS_SUCCESS = 'GET_QB_COST_OF_GOODS_SUCCESS';
export var GET_QB_COST_OF_GOODS_FAILURE = 'GET_QB_COST_OF_GOODS_FAILURE';
export var GET_EXPENSE_ACCOUNTS_SUCCESS = 'GET_EXPENSE_ACCOUNTS_SUCCESS';
export var GET_EXPENSE_ACCOUNTS_FAILURE = 'GET_EXPENSE_ACCOUNTS_FAILURE';
export var GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';
export var GET_CLASSES_FAILURE = 'GET_CLASSES_FAILURE';
export var GET_QB_TAX_CODES_SUCCESS = 'GET_QB_TAX_CODES_SUCCESS';
export var GET_QB_TAX_CODES_FAILURE = 'GET_QB_TAX_CODES_FAILRUE';
export var GET_QB_COMPANY_INFO_SUCCESS = 'GET_QB_COMPANY_INFO_SUCCESS';
export var GET_QB_COMPANY_INFO_FAILURE = 'GET_QB_COMPANY_INFO_FAILRUE';
export var QB_REFRESH_TOKEN_EXPIRED = 'QB_REFRESH_TOKEN_EXPIRED'; // --=--------SYNC QUICKBOOKS-------------//

export var SYNC_QB_FAILURE = 'SYNC_QB_FAILURE';
export var SYNC_QB_SUCCESS = 'SYNC_QB_SUCCESS';
export var DISCONNECT_QB_FAILURE = 'DISCONNECT_QB_FAILURE';
export var DISCONNECT_QB_SUCCESS = 'DISCONNECT_QB_SUCCESS';
export var DUPLICATE_INVOICE_FAILURE = 'DUPLICATE_INVOICE_FAILURE';
export var HIDE_DUPLICATE_WARNING_MODAL = 'HIDE_DUPLICATE_WARNING_MODAL'; // ----------------reminder----------------//

export var GET_REMINDERS_REQUEST = 'GET_REMINDERS_REQUEST';
export var GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS';
export var GET_REMINDERS_FAILURE = 'GET_REMINDERS_FAILURE';
export var GET_REMINDER_REQUEST = 'GET_REMINDER_REQUEST';
export var GET_REMINDER_SUCCESS = 'GET_REMINDER_SUCCESS';
export var GET_REMINDER_FAILURE = 'GET_REMINDER_FAILURE';
export var CREATE_REMINDER_REQUEST = 'CREATE_REMINDER_REQUEST';
export var CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
export var CREATE_REMINDER_FAILURE = 'CREATE_REMINDER_FAILURE';
export var UPDATE_REMINDER_REQUEST = 'UPDATE_REMINDER_REQUEST';
export var UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export var UPDATE_REMINDER_FAILURE = 'UPDATE_REMINDER_FAILURE';
export var DELETE_REMINDER_REQUEST = 'DELETE_REMINDER_REQUEST';
export var DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export var DELETE_REMINDER_FAILURE = 'DELETE_REMINDER_FAILURE';
export var GET_REGARDING_VEHICLE_REQUEST = 'GET_REGARDING_VEHICLE_REQUEST';
export var GET_REGARDING_VEHICLE_SUCCESS = 'GET_REGARDING_VEHICLE_SUCCESS';
export var GET_REGARDING_VEHICLE_FAILURE = 'GET_REGARDING_VEHICLE_FAILURE';
export var REVERIFY_USER_SUCCESS = 'REVERIFY_USER_SUCCESS';
export var REVERIFY_USER_FAILURE = 'REVERIFY_USER_FAILURE'; // --=--------PLAID-------------//

export var PUBLIC_TOKEN_SUCCESS = 'PUBLIC_TOKEN_SUCCESS';
export var PUBLIC_TOKEN_FAILURE = 'PUBLIC_TOKEN_FAILURE'; // ----------------Payment---------------- //

export var COMPLETE_STRIPE_OAUTH = 'COMPLETE_STRIPE_OAUTH';
export var GET_STRIPE_LOGIN_LINK = 'GET_STRIPE_LOGIN_LINK';
export var GET_STRIPE_LOGIN_LINK_SUCCESS = 'GET_STRIPE_LOGIN_LINK_SUCCESS';
export var GET_STRIPE_LOGIN_LINK_FAILURE = 'GET_STRIPE_LOGIN_LINK_FAILURE';
export var CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export var CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
export var PAYMENT_RECEIPT_REQUEST = 'PAYMENT_RECEIPT_REQUEST';
export var PUT_PAYMENT_RECEIPT_SUCCESS = 'PUT_PAYMENT_RECEIPT_SUCCESS';
export var PUT_PAYMENT_RECEIPT_FAILURE = 'PUT_PAYMENT_RECEIPT_FAILURE';
export var OFFLINE_PAYMENT_SUCCESS = 'OFFLINE_PAYMENT_SUCCESS';
export var OFFLINE_PAYMENT_FAILURE = 'OFFLINE_PAYMENT_FAILURE';
export var GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export var GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export var GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';
export var GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export var GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';
export var GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export var GET_PAYMENT_SUMMARY_REQUEST = 'GET_PAYMENT_SUMMARY_REQUEST';
export var GET_PAYMENT_SUMMARY_FAILURE = 'GET_PAYMENT_SUMMARY_FAILURE';
export var GET_PAYMENT_SUMMARY_SUCCESS = 'GET_PAYMENT_SUMMARY_SUCCESS';
export var PAYMENT_REFUND_SUCCESS = 'PAYMENT_REFUND_SUCCESS';
export var PAYMENT_REFUND_FAILURE = 'PAYMENT_REFUND_FAILURE';
export var DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export var DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export var DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';
export var RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';
export var RESET_PAYMENT = 'RESET_PAYMENT';
export var ORDER_CARD_READER_REQUEST = 'ORDER_CARD_READER_REQUEST';
export var ORDER_CARD_READER_SUCCESS = 'ORDER_CARD_READER_SUCCESS';
export var ORDER_CARD_READER_FAILURE = 'ORDER_CARD_READER_FAILURE';
export var STRIPE_IDENTITY_REQUEST = 'STRIPE_IDENTITY_REQUEST';
export var STRIPE_IDENTITY_REQUEST_SUCCESS = 'STRIPE_IDENTITY_REQUEST_SUCCESS';
export var STRIPE_IDENTITY_REQUEST_FAILURE = 'STRIPE_IDENTITY_REQUEST_FAILURE';
export var STRIPE_CONNECT_SIGNUP_REQUEST = 'STRIPE_CONNECT_SIGNUP_REQUEST';
export var STRIPE_CONNECT_SIGNUP_REQUEST_SUCCESS = 'STRIPE_CONNECT_SIGNUP_REQUEST_SUCCESS';
export var STRIPE_CONNECT_SIGNUP_REQUEST_FAILURE = 'STRIPE_CONNECT_SIGNUP_REQUEST_FAILURE';
export var QUOTE_CARD_READER_ORDER_REQUEST = 'QUOTE_CARD_READER_ORDER_REQUEST';
export var QUOTE_CARD_READER_ORDER_SUCCESS = 'QUOTE_CARD_READER_ORDER_SUCCESS';
export var QUOTE_CARD_READER_ORDER_FAILURE = 'QUOTE_CARD_READER_ORDER_FAILURE';
export var HIDE_LENDING_VIEWS = 'HIDE_LENDING_VIEWS'; //time tracking running

export var TIMER_RUNNING = 'TIMER_RUNNING';
export var TIMER_INIT = 'TIMER_INIT';
export var TIMER_UPDATE_SERVICE_RUNNING = 'TIMER_UPDATE_SERVICE_RUNNING';