/* eslint-disable camelcase */
import axios from 'axios';
import { METHOD_TYPES } from "../core/constants";
import config from "../core/config";
export function getHeader(curEmp) {
  var header = {};
  var access_token;

  if (curEmp) {
    access_token = curEmp.access_token;
  } else {
    access_token = localStorage.access_token || null;
  }

  header.Accept = 'application/json';
  header['Content-Type'] = 'application/json';
  header['media-type'] = 'application/json';

  if (access_token) {
    header.access_token = access_token;
  }

  return header;
}

function HttpRequest() {}

function getUrl(url) {
  return config.mtbAPI + url;
}

function async(method, url, body, callback) {
  var headers = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : getHeader();
  var apiUrl = getUrl(url);
  return axios({
    method: method,
    url: apiUrl,
    data: method === METHOD_TYPES.GET ? null : body,
    headers: headers
  }).then(function (response) {
    var data = response.data,
        status = response.status;
    if (callback) return callback(null, data, status);
    return response;
  })["catch"](function (error) {
    var response = error.response;
    if (callback) return callback(response);
    return response;
  });
}

function asyncAmzn(method, url, body, callback) {
  var apiUrl = url;
  var headers = {
    'Content-Type': 'text/csv'
  };
  return axios({
    method: method,
    url: apiUrl,
    data: body,
    headers: headers
  }).then(function (response) {
    var data = response.data,
        status = response.status;
    if (callback) return callback(null, data, status);
    return response;
  })["catch"](function (error) {
    var response = error.response;
    if (callback) return callback(response);
    return response;
  });
}

function asyncWithOriginRoute(method, url, body) {
  var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : getHeader();
  var apiUrl = body.type && ['timeline', 'sale', 'payable', 'conversion', 'tips', 'payment', 'efficiency'].includes(body.type) ? "".concat(getUrl(url), "?background=true") : getUrl(url);
  return axios({
    method: method,
    url: apiUrl,
    data: method === METHOD_TYPES.GET ? null : body,
    headers: headers
  }).then(function (response) {
    return response;
  })["catch"](function (error) {
    var response = error.response;
    return response;
  });
}

HttpRequest.get = function (url) {
  return async(METHOD_TYPES.GET, url, {}).then(function (response) {
    return response.data.success ? response.data : Promise.reject(response);
  });
};

HttpRequest.getWithHeaders = function (url, callback, headers) {
  return async(METHOD_TYPES.GET, url, {}, callback, headers);
};

HttpRequest.post = function (url, data) {
  return async(METHOD_TYPES.POST, url, data).then(function (response) {
    return response.data.success ? response.data : Promise.reject(response);
  });
};

HttpRequest.postOriginRoute = function (url, data) {
  return asyncWithOriginRoute(METHOD_TYPES.POST, url, data).then(function (response) {
    return response.data.success ? response.data : Promise.reject(response);
  });
};

HttpRequest.put = function (url, data) {
  return async(METHOD_TYPES.PUT, url, data).then(function (response) {
    return response.data.success ? response.data : Promise.reject(response);
  });
};

HttpRequest.putAmzn = function (data, url) {
  return asyncAmzn(METHOD_TYPES.PUT, url, data).then(function (response) {
    return response.status === 200 ? response.data : Promise.reject(response);
  });
};

HttpRequest["delete"] = function (url, data) {
  return async(METHOD_TYPES.DELETE, url, data).then(function (response) {
    return response.data.success ? response.data : Promise.reject(response);
  });
};

export default HttpRequest;