import _ from 'lodash';
import toast from "../components/libraries/toast";
import * as types from "../core/actionTypes";
import * as reportingServices from "../services/reportingServices";
import { catchError } from "../core/error.handler";
import { jobPolling } from "./mainActions";
export var getSalesData = function getSalesData(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return reportingServices.getSalesData(params).then(function (response) {
      dispatch({
        type: types.GET_SALES_DATA_SUCCESS
      });
      return response;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_SALES_DATA_FAILURE);
    });
  };
};
export var getSalesChart = function getSalesChart(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return reportingServices.getSalesChart(params).then(function (response) {
      dispatch({
        type: types.GET_SALES_CHART_DATA_SUCCESS
      });
      return response;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_SALES_CHART_DATA_FAILURE);
    });
  };
};

var mapFromReportsDto = function mapFromReportsDto(items) {
  return items;
};

function downloadLink(fileName, link) {
  var a = document.createElement('a');
  a.style = 'display: none';
  a.href = link;
  document.body.appendChild(a);
  a.setAttribute('download', fileName);
  a.setAttribute('target', '_blank');
  a.click();
  document.body.removeChild(a);
}

function printLink(link) {
  var iframe = document.querySelector('#print');
  iframe.src = window.URL.createObjectURL(new Blob(link, {
    type: 'application/pdf'
  }));

  iframe.onload = function () {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    document.querySelector('.backdrop').classList.remove('show');
    document.body.classList.remove('unscrollable'); // this.setState({
    //   loading: false,
    // });
  };
}

export var pdfReport = function pdfReport(data, type) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    data.exportType = 'pdf';
    return reportingServices.pdfReport(data).then(function (result) {
      return jobPolling(result.data.job);
    }).then(function (response) {
      if (type === 'download') downloadLink("report_".concat(response.data.job), response.data.job.data.url);
      dispatch({
        type: types.GET_PDF_SUCCESS
      });
      return response;
    })["catch"](function (error) {
      catchError(error, dispatch, types.GET_PDF_FAILURE);
      throw error;
    });
  };
};
export var searchReports = function searchReports(filters, callback) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    reportingServices.searchReports(filters).then(function (result) {
      if (result && result.data && !result.data.redirect) {
        var reports = mapFromReportsDto(result.data);
        var action = dispatch({
          type: types.GET_REPORTS_SUCCESS,
          reports: reports
        });
        callback && callback(result);
        return action;
      }

      if (result && result.data && result.data.redirect) {
        var _reports = result.data;
        var redirect = result.data.redirect;

        var _action = dispatch({
          type: types.GET_REPORTS_SUCCESS,
          reports: [_reports],
          redirect: redirect
        });

        callback && callback(result);
        return _action;
      }
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_REPORTS_FAILURE);
    });
  };
};
export var sendEmail = function sendEmail(params, callback) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    reportingServices.sendEmail(params).then(function () {
      if (callback !== undefined) callback.call();
      toast.success('Email Sent');
      return dispatch({
        type: types.SEND_EMAIL_SUCCESS
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.SEND_EMAIL_FAILURE);
    });
  };
};
export var exportReportsCSV = function exportReportsCSV(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return reportingServices.exportReportsCSV(params).then(function (result) {
      return jobPolling(result.data.job);
    }).then(function (response) {
      downloadLink('Report.CSV', response.data.job.data.report);
      dispatch({
        type: types.EXPORT_REPORT_CSV_SUCCESS
      });
      return response;
    })["catch"](function (error) {
      catchError(error, dispatch, types.EXPORT_REPORT_CSV_FAILURE);
      throw error;
    });
  };
};