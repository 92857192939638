import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getSalesData(query) {
  var path = routesUtils.getApiPath(API.REPORTING_GET_SALES_DATA, query);
  return HttpRequest.get(path);
}
export function getSalesChart(params) {
  var path = routesUtils.getApiPath(API.REPORTING_SALES_CHART_DATA);
  return HttpRequest.post(path, params);
}
export function pdfReport(data) {
  console.log(data);
  var path = routesUtils.getApiPath(API.GET_PDF_REPORTS);
  return HttpRequest.post("".concat(path, "?background=true"), data);
}
export function sendEmail(data) {
  console.log(data);
  var path = routesUtils.getApiPath(API.SEND_REPORT_EMAIL);
  return HttpRequest.post(path, data);
}
export function exportReportsCSV(data) {
  var path = routesUtils.getApiPath(API.REPORTS);
  return HttpRequest.postOriginRoute(path, data);
}